import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Tooltip } from '@mui/material';
import { clearFilters, removeSpatialFilter } from 'lib/react-geo-tool/packages/react-redux/src/';
import { 
  clearWidgetColors, 
  setListFilterPolygonData, 
  setListPolygonWidgetForDelete, 
  setListRecommendationsWidgetForDelete,
  setAsDeletedPolygon,
} from 'store/appSlice';
import { useTranslation } from 'components/providers/TranslationProvider';
import {
  MuiDeleteSweepIcon,
  MuiDivRoot,
  MuiIconButton
} from './styles/cleanFiltersAndColors';

 function CleanFiltersAndColors () {
  const dataSources = useSelector((state) => state.carto.dataSources)
  const widgetId = useSelector((state) => state.app.selectWidgetId)
  const isWidgetsPanelVisible = useSelector((state) => state.app.isWidgetsPanelVisible);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleClean = () => {
    for (let key in dataSources) {
        if (dataSources[key].id) {
            const idValue = dataSources[key].id;
            dispatch(clearFilters(idValue));
        }
    }
	dispatch(removeSpatialFilter());
	dispatch(setListFilterPolygonData({}));
	dispatch(setListPolygonWidgetForDelete({id:widgetId, selection:[]}));
	dispatch(setListRecommendationsWidgetForDelete({id:widgetId, selection:[]}));
  dispatch(clearWidgetColors());
  dispatch(setAsDeletedPolygon(true));
}
  return (
	<MuiDivRoot>
		<Tooltip title={t('clean_filters_colors')}>
		<MuiIconButton
			onClick={() => handleClean()}
			style={{ right: isWidgetsPanelVisible ? '502px' : '68px' }}
      	>
	  	<MuiDeleteSweepIcon/>
      </MuiIconButton>
	  </Tooltip>
	</MuiDivRoot>
  )
 }

 export default CleanFiltersAndColors
