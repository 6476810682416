import React from 'react';
import { Grid } from '@mui/material';
import VariableInfo from 'components/common/VariableInfo';

function StatisticsWidgetUI(props) {
  const { data, unfilteredData } = props;
  return (
    <div style={{ width: '406px', marginBottom: '10px' }}>
      <Grid container sx={{width: '406px', gap: '12px'}}>
        {data?.map(
          (elem, index) => {
              const unfilteredItem = unfilteredData.find( ( d ) => elem.field === d.field );
              return elem.visible && (
              <Grid item xs={6} key={`container-${elem.id || index}`} sx={{width: '197px', maxWidth: '197px !important'}}>
                <VariableInfo
                  prefix = { elem.prefix||null }
                  label = { elem.name}
                  data = { unfilteredItem?.result}
                  name = { elem?.suffix||null }
                  filterData = { elem?.result }
                  filterUnit = { elem?.suffix }
                  key = { elem.id }
                  isStatisticWidget={true}
                />
              </Grid>
            ) }
        )}
      </Grid>
    </div>
  );
}

export default StatisticsWidgetUI;
