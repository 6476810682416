import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import ComputeRecommendationsFormModal from './ComputeRecommendationsFormModal';
import GenerateRecommendationsFormModal from './GenerateRecommendationsFormModal';
import { ThemeProvider } from '@material-ui/core/styles';
import themeIncluia from 'theme/incluia';
import { processJobStatus} from 'utils/constants';

const GenerateOrComputeRecommendations = ( props ) => {
  const { defaultValues, dataForOP, widget, dataForRecom, demandDatasetColumnsIsSuccess, demandDatasetColumns, setDataForRecom, setDataForOP, widgetId, setProcessJobName  } = props;
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState('compute');

  const location = useLocation();
  const navigate = useNavigate();

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  React.useEffect(() => {
    setAction( query.get('recommendations') );
    if (action === 'generate' || action === 'compute' ) {
      setOpen(true);
      setProcessJobName(action);
    }
  }, [query, action]);

  const handleClose = (event, reason) => {
    if ( reason === 'backdropClick' || reason === 'escapeKeyDown' ) {
      setOpen( true )
    } else {
      if ( action === 'compute' || action === 'generate' ) query.delete('recommendations');
      setOpen(false);
      navigate(`${location.pathname}${query && ''}${query}`, { replace: true });
      setProcessJobName( '' );
    }
  };

  React.useEffect( () => {
    if ( defaultValues.widgetProcessJobStatus === processJobStatus.SUCCESS ) {
      defaultValues.setWidgetProcessJobStatus( processJobStatus.WAITING );
      handleClose();
    }
  }, [ defaultValues.widgetProcessJobStatus ]);

  return (
    <>
      {open && action === 'compute' && (
        <ComputeRecommendationsFormModal widget={widget} defaultValues={defaultValues} dataForOP={dataForOP} action={action} open={open} onClose={handleClose} setDataForOP={setDataForOP} widgetId={widgetId}/>
      )}
      {open && action === 'generate' && (
        <GenerateRecommendationsFormModal widget={widget} defaultValues={defaultValues} action={action} open={open} onClose={handleClose} dataForRecom={dataForRecom} setDataForRecom={setDataForRecom} widgetId={widgetId}/>
      )}
    </>
  );

};
export default GenerateOrComputeRecommendations;
