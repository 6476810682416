/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, {useState} from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {setSelectedListWidget} from 'store/appSlice';
import {useDispatch, useSelector} from 'react-redux';
import {
  Box,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  InputAdornment, Menu, MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import SearchIcon
  from "../../../../lib/react-geo-tool/packages/react-ui/src/assets/icons/SearchIcon";
import {FileDownloadOutlined, KeyboardArrowUp} from "@mui/icons-material";
import {useTranslation} from "../../../providers/TranslationProvider";
import {FiberManualRecord, KeyboardArrowDown} from "@mui/icons-material";
import {jsPDF} from "jspdf";
import 'jspdf-autotable';
import {
  gridContainerStyle, MuiBoxContainer, MuiBoxRow,
  MuiDivRoot, tableContainerStyle, tableHeadStyle, tablePaginationStyle,
  textFieldStyle,
  listSearchIconStyle,
} from '../styles/listWidgetUI';
import {
  MuiIconButtonHover
} from '../recommendations/styles/recommendationsWidgetUI';

function ListWidgetUI(props) {

  const {data, onSelect, id} = props;
  const {columns: dataCols = [], values: originalDataRows = []} = data || {};
  const dataRows = originalDataRows.map((row, index) => ({...row, id: index}));
  const [viewSelected, setViewSelected] = React.useState(null);
  const selectedListWidget = useSelector((state) => state.app.selectedListWidget);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [listExport, setListExport] = React.useState([])

  const [open, setOpen] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [anchorEl, setAnchorEl] = useState(null);
  const openDownload = Boolean(anchorEl);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilteredData, setSearchFilteredData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);


  React.useEffect(() => {
    if (data) {
      setFilteredData(dataRows);
    }
  }, [data]);

  React.useEffect(() => {
    setSearchFilteredData(filteredData)
  }, [filteredData])
  const handleClick = (id) => {
    setOpen(prev => ({...prev, [id]: !prev[id]}));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleView = (row) => () => {
    setViewSelected(row.id);
    onSelect(row.geom);
    let selectedListW = {...selectedListWidget, [id]: row.id}
    dispatch(setSelectedListWidget(selectedListW));
  }
  const isSelected = (id) => {
    return listExport.indexOf(id) !== -1
  }
  const handleSelect = (id) => () => {
    if (id === 'all') {
      if (listExport.length === dataRows.length) {
        setListExport([]);
      } else {
        setListExport(dataRows.map(row => row.id));
      }
    } else {
      const currentIndex = listExport.indexOf(id);
      const newChecked = [...listExport];
      if (currentIndex === -1) {
        newChecked.push(id);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setListExport(newChecked);
    }
  }

  const handleClickDownload = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleExport = (type) => {
    if (type === 'PDF') {
      const doc = new jsPDF();
      const visibleCols = dataCols.filter(col => col.visible);
      const headers = [visibleCols.map(col => col.name)];
      const data = listExport.length > 0
        ? searchFilteredData.filter(row => listExport.includes(row.id))
        : searchFilteredData;
      const tableRows = data.map(row => {
        return visibleCols.map(col => col.calculator ? row[col.alias] : row[col.field] || "");
      });
      doc.autoTable({
        head: headers,
        body: tableRows,
        startY: 10,
        headStyles: {fillColor: localStorage.getItem('colorPrimary')}
      });
      doc.save("data-list.pdf");
    } else if (type === 'CSV') {
      const visibleCols = dataCols.filter(col => col.visible);
      const rowsToInclude = listExport.length > 0
        ? searchFilteredData.filter(row => listExport.includes(row.id))
        : searchFilteredData;
      const csvData = [
        visibleCols.map(col => col.name).join(','),
        ...rowsToInclude.map(row => visibleCols.map(col => `${col.calculator ? row[col.alias] : row[col.field] || ""}`).join(','))
      ].join('\n');
      const blob = new Blob([csvData], {type: 'text/csv;charset=utf-8;'});
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'data-list.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    handleClose();
  };
  const handleSearchChange = (event) => {
    const term = event.target.value;
    const dataT = [...dataRows]
    const newData = dataT.filter(row => {
      return Object.keys(row).some(key =>
        String(row[key]).toLowerCase().includes(term.toLowerCase())
      );
    });
    setSearchTerm(term)
    setSearchFilteredData(newData);
  };

  return (
    <MuiDivRoot id='list-container'>
      <Grid container style={{height: '460px'}}>
        <Grid container item direction='row'
              style={gridContainerStyle}
              id='list-search-container-box'>
          <Grid item xs={11}>
            <TextField
              variant="outlined"
              placeholder={t('search')}
              sx={textFieldStyle}
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start' sx={{ width: '32px', height: '32px' }}>
                    <SearchIcon style={listSearchIconStyle}/>
                  </InputAdornment>
                )
              }}/>
          </Grid>
          <Grid item xs={1}>
            <MuiIconButtonHover
              onClick={handleClickDownload}>
              <FileDownloadOutlined/>
            </MuiIconButtonHover>
            <Menu
              id="export-menu"
              anchorEl={anchorEl}
              keepMounted
              open={openDownload}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleExport('CSV')}><Typography
                variant='caption' sx={{fontFamily: 'Montserrat'}}>Export to
                CSV</Typography></MenuItem>
              <MenuItem onClick={() => handleExport('PDF')}><Typography
                variant='caption' sx={{fontFamily: 'Montserrat'}}>Export to
                PDF</Typography></MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <Grid item style={{height: '400px', width:'100%'}} id='list-table-container-grid'>
          <MuiBoxContainer>
            <TableContainer sx={tableContainerStyle}>
              <Table>
                <TableHead sx={tableHeadStyle}>
                  <TableRow>
                    <TableCell sx={cellCheck}>
                      <Checkbox sx={check}
                                checked={listExport.length === dataRows.length && dataRows.length > 0}
                                indeterminate={listExport.length > 0 && listExport.length < dataRows.length}
                                onClick={handleSelect('all')}
                                style={{color: listExport.length ? localStorage.getItem('colorPrimary') : 'gray'}}
                      /> </TableCell>
                    {dataCols.map((col, index) => (
                      index < 3 && col.visible ? <TableCell key={index}
                                                            sx={cellHead}>{col.name}</TableCell> : null
                    ))}
                    <TableCell/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchFilteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow
                        sx={{'& > *': {borderBottom: 'unset'}, ...rowCell}}>
                        <TableCell align="center">
                          <MuiBoxRow>
                            <Checkbox sx={check}
                                      style={{color: isSelected(row.id) ? localStorage.getItem('colorPrimary') : 'gray'}}
                                      checked={isSelected(row.id)}
                                      onClick={handleSelect(row.id)}/>
                            <VisibilityIcon style={{
                              fontSize: 34,
                              padding: 8,
                              color: row.id === viewSelected ? localStorage.getItem('colorPrimary') : 'gray'
                            }} onClick={handleView(row)}/>
                          </MuiBoxRow>
                        </TableCell>

                        {dataCols.map((col, colIndex) => (
                          colIndex < 3 && col.visible ? <TableCell
                            key={col.field}>{col.calculator ? row[col.alias] : row[col.field]}</TableCell> : null
                        ))}
                        <TableCell>
                          { (dataCols.length > 3) && (<IconButton size="small"
                                      onClick={() => handleClick(index)}>
                            {open[index] ? <KeyboardArrowUp/> :
                              <KeyboardArrowDown/>}
                          </IconButton>) }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{paddingBottom: 0, paddingTop: 0}}
                                   colSpan={6}>
                          <Collapse in={open[index]} timeout="auto"
                                    unmountOnExit>
                            <Box sx={{margin: 1}}>
                              <Grid container>
                                {dataCols.map((col, colIndex) => (
                                  colIndex >= 3 && col.visible ? (
                                    <Grid item xs={6} key={colIndex}>
                                      <Typography variant="overline"
                                                  component="div" sx={{
                                        fontFamily: 'Montserrat',
                                        color: '#616161'
                                      }}>
                                        {col.name}
                                      </Typography>
                                      <Typography variant="body2" sx={{
                                        color: '#212121',
                                        fontFamily: 'Montserrat'
                                      }}>
                                        <span style={{
                                          verticalAlign: 'middle',
                                          marginRight: '6px'
                                        }}>
                                          <FiberManualRecord style={{
                                            fontSize: 'small',
                                            color: '#212121'
                                          }}/></span>
                                        {col.calculator ? row[col.alias] : row[col.field]}
                                      </Typography>
                                    </Grid>
                                  ) : null
                                ))}
                              </Grid>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={searchFilteredData.length}
              page={page}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              color="secondary"
              labelDisplayedRows={({
                                     from,
                                     to,
                                     count
                                   }) => `Showing ${from} to ${to} of ${count}`}
              rowsPerPageOptions={[]}
              showLastButton
              sx={tablePaginationStyle}
            />
          </MuiBoxContainer>
        </Grid>
      </Grid>
    </MuiDivRoot>
  );
}

const check = {
  padding: '8px !important',
  '& .MuiSvgIcon-root': {
    fontSize: 18,
  },
  '& .MuiButtonBase-root-MuiCheckbox-root': {
    width: 34,
    height: 34,
    padding: '8px !important',
  },
  '& .MuiTableCell-root': {
    textAlign: 'left',
  }
}
const cellHead = {
  textAlign: 'center',
  position: 'relative',
  height: '34px',
  '&:not(:last-child)': {
    // borderRight: '1px solid rgba(224, 224, 224, 1)',
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '50%',
      width: '1px',
      height: 'calc(100% - 20px)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      transform: 'translateY(-50%)',
    },
    // borderRight: 'none',
  }
}
const rowCell = {
  '& .MuiTableCell-root': {
    height: '52px',
    padding: 0,
    color: '#757575',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    textAlign: 'left'
  },
  '& .MuiTableCell-root:not(:first-of-type)': {
    paddingLeft: '10px',
  },
}
const cellCheck = {
  textAlign: 'left',
  position: 'relative',
  height: '34px',
  width: '70px',
  '&:not(:last-child)': {
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '50%',
      width: '1px',
      height: 'calc(100% - 20px)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      transform: 'translateY(-50%)',
    }
  }
}

export default ListWidgetUI;
