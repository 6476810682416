import * as React from 'react';
import {useSelector} from 'react-redux';
import themeIncluia from 'theme/incluia';
import { 
  MuiToolbar, 
  nonSelectedTab,
  muiTabs,
  muiTab,
  tabStyle,
} from './styles/widgetsTabs';
import Widgets from './Widgets';
import {GLOBAL} from 'utils/constants';
import {
  Tab,
  Tabs,
  Grid,
  ThemeProvider,
  Paper,
  Container 
} from '@mui/material';

const WidgetsTabs = () => {

  const tabs = useSelector((state) => state.app.tabs);
  const [activeTab, setActiveTab] = React.useState('');


  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  React.useEffect(() => {
    if (tabs[0]) setActiveTab(tabs[0]?.id);
  }, [tabs]);

  const dinamicStyles = index => (index === activeTab ? {} : nonSelectedTab);

  return (
    <Container disableGutters fixed sx={{width: '434px'}}>
      {activeTab && (
        <>
          <ThemeProvider theme={themeIncluia}>
            <MuiToolbar disableGutters sx={{ paddingTop: '12px' }}>
              <Grid container component={Paper} elevation={0} spacing={4}
                    style={{
                      margin: 0,
                      zIndex: 1201,
                      borderBottom: `1px solid ${GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[200]}`,
                      width: '100%',
                      backgroundColor: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[50],
                      borderRadius: '20px 0px 0px 0px',
                      marginLeft: '12px',
                    }}>
                <Tabs
                  onChange={handleChange}
                  value={activeTab}
                  textColor='primary'
                  variant='scrollable'
                  scrollButtons={true}
                  allowScrollButtonsMobile
                  orientation='horizontal'
                  sx={muiTabs}
                >
                  {tabs.map((w, index) => (
                    <Tab
                      label={w.name}
                      value={w.id}
                      key={w.id}
                      sx={{...muiTab, ...tabStyle, ...dinamicStyles(index) }}
                    />
                  ))}
                </Tabs>
              </Grid>
            </MuiToolbar>
          </ThemeProvider>
          <Grid style={{
            maxHeight: 'calc(87vh - 48px)',
            overflowY: 'auto',
            '&::WebkitScrollbar': {
              width: '3px'  // Adjust the width as needed
            },
            '&::WebkitScrollbarTrack': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)'
            },
            '&::WebkitScrollbarThumb': {
              backgroundColor: 'darkgrey',
              outline: '1px solid slategrey'
            }
          }}>
            <Widgets tabId={activeTab}/>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default WidgetsTabs;
