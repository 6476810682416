import { Box, styled } from '@mui/material';
import { GLOBAL } from '../../../../utils/constants';

export const MuiDivRoot = styled('div')(({ theme }) => ({
  width: '100%',
  height: 462,
  '&, & *': {
    '&::-webkit-scrollbar': {
      backgroundColor: '#ebeef2',
      height: 5,
      borderRadius: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#d3dbe5',
      borderRadius: 3,
    },
  },
}));

export const MuiBoxContainer = styled(Box)(({ theme }) => ({
  borderRadius: '12px',
  border: '1px solid #E0E0E0'
}));

export const MuiBoxRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}));

export const gridContainerStyle = {
  height: '50px',
  width: '100%'
};

export const textFieldStyle = {
  width: '314px',
  '& .MuiInputBase-root': {
    height: '36px',
    borderRadius: '18px',
    border:`1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[400]}`,
    fontFamily: 'Montserrat',
    color: '#424242',
    fontSize: '14px',
    padding: '10px',
    '&:hover': {
      border:`1px solid ${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[400]}`,
    },  
  },
};

export const tableHeadStyle = {
  '& .MuiTableCell-root': {
    height: '34px',
    color: '#424242',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    textAlign: 'left',
    padding: 0
  },
  '& .MuiTableCell-root:not(:first-of-type)': {
    paddingLeft: '10px',
  },
};

export const tablePaginationStyle = {
  '.MuiTablePagination-toolbar': {
    flexWrap: 'wrap',
    minHeight: '48px',
    padding: '0 10px 0 10px'
  },
  '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
    marginRight: '2px',
    marginLeft: '0px',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    color: '#475569',

  },
  '& .MuiTablePagination-actions': {
    marginTop: '4px',
    marginBottom: '4px',    
  }
};

export const tableContainerStyle = {
  maxHeight: '370px',
  '&::-webkit-scrollbar': {
    width: '3px'
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'darkgrey',
    outline: '1px solid slategrey'
  }
};

export const listSearchIconStyle = {
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800],
  height: '18px',
  width: '18px',
  borderRadius:'50%',
}