import * as React from 'react';
import swap from 'lodash-move';
import _ from 'lodash';
import {
  Typography,
  Box,
  Checkbox, Divider
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useLocation, useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import useCanManageRecomendationWidgetsFilter from 'api/hooks/useCanManageRecomendationWidgetsFilter';
import { useAuth } from 'components/providers/AuthProvider';

import {
  setWidgets,
  setIsValidDescriptiveScenario,
  updateWidget
} from 'store/appSlice';
import {
  MuiDeleteIcon,
  MuiEditIcon,
  MuiVisibilityIcon,
  MuiVisibilityOffIcon
} from '../../layer/styles/layersSort';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import ListIcon from '@mui/icons-material/List';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ParametersIcon from "@mui/icons-material/Tune"
import {
  boxStyle,
  commonIconsWidgetStyle, dragHandleStyle, MuiGrid,
  MuiGridContainer, MuiGridDrag, MuiGridItem, MuiGridSorteableList
} from './styles/widgetsSort';
import {
  customActionsStyle,
  customVisibilityStyle
} from './styles/widgetsTabs';

const DragHandle = SortableHandle(() => (
  <DragHandleIcon color='primary' style={dragHandleStyle} />
));

const SortableItem = SortableElement(({ item, groupId, handleVisibilityChange }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleDelete = (id) =>
    navigate(`${location.pathname}?delete-widget=${id}&group-id=${groupId}`, {
      replace: true,
      state: item,
    });

  const handleEdit = (id) => {
    navigate(`${location.pathname}?edit-widget=${id}&group-id=${groupId}`, {
      replace: true,
      state: item,
    });
  };

  const Controls = () => (
    <MuiGrid item xs={3}>
      <Checkbox
        checked={item.visible ?? true}
        name='visibility'
        color='primary'
        style={customVisibilityStyle}
        icon={<MuiVisibilityOffIcon style={{ width: '26px', height: '26px ', padding: '5px' }}/>}
        checkedIcon={<MuiVisibilityIcon style={{ width: '26px', height: '26px ', padding: '5px' }}/>}
        onChange={(e) => handleVisibilityChange(item.id, e.target.checked)}
      />
      <MuiEditIcon
        style={customActionsStyle}
        onClick={() => handleEdit(item.id)}
      />
      <MuiDeleteIcon
        style={customActionsStyle}
        onClick={() => handleDelete(item.id)}
      />
    </MuiGrid>
  );

  const IconsByWidgetType = ({ type }) => {
    return (
      <>
        {type === 'statistics' && (
          <ViewStreamIcon
            style={{ ...commonIconsWidgetStyle, background:'#FF9800', width:'16px', height: '16px', padding: '2px'}}
          />
        )}
        {type === 'histogram' && (
          <BarChartOutlinedIcon
            style={{ ...commonIconsWidgetStyle, background:'#607D8B', width:'16px', height: '16px', padding: '2px' }}/>
        )}
        {type === 'category' && (
          <BarChartOutlinedIcon
            style={{ ...commonIconsWidgetStyle, background:'#FF9800', transform: 'rotate(90deg)', width:'16px', height: '16px', padding: '2px' }}
          />
        )}
        {type === 'polygon' && (
          <CategoryIcon
            style={{ ...commonIconsWidgetStyle, background:'#607D8B', width:'16px', height: '16px', padding: '2px'}}
          />
        )}
        {type === 'list' && (
          <ListIcon
            style={{ ...commonIconsWidgetStyle, background:'#607D8B', width:'16px', height: '16px', padding: '2px'}}
          />
        )}
        {type === 'recommendations' && (
          <ThumbUpAltIcon
            style={{ ...commonIconsWidgetStyle, background:'#607D8B', width:'16px', height: '16px', padding: '2px' }}
          />
        )}
        {type === 'parameters' && (
          <ParametersIcon
            style={{ ...commonIconsWidgetStyle, background:'#607D8B', width:'16px', height: '16px', padding: '2px' }}
          />
        )}
      </>
    )
  }

  return (
    <MuiGridContainer container>
      <MuiGridDrag item>
        <DragHandle />
      </MuiGridDrag>
      <MuiGridItem item xs={7}>
        <Box
          style={{
            ...boxStyle,
            background: item.color + Math.round(item.opacity * 255).toString(16),
            marginLeft: '-1.8rem',
            width: '16px',
            height: '16px'
          }}
        />
        <IconsByWidgetType type={item.type}/>
        <Typography
          variant='caption'
          fontSize={12}
          color={'#212121'}
          letterSpacing={0.4}
          fontWeight={400}
          fontFamily={'Montserrat'}
          noWrap
          title={item.name}
        >
          {item.name}
        </Typography>
      </MuiGridItem>
      <Controls/>
    </MuiGridContainer>
  );
});

const SortableList = SortableContainer(({ items, groupId, handleVisibilityChange }) => (
  <MuiGridSorteableList container >
    {items?.map((item, index) => (
      <Box key={item.id} sx={{width:'100%'}}>
        <SortableItem
          key={item.id}
          index={index}
          item={item}
          groupId={groupId}
          handleVisibilityChange={handleVisibilityChange}
        />
        <Divider sx={{width:'98%'}}/>
      </Box>
    ))}
  </MuiGridSorteableList>
));

const WidgetSort = ({ tabId }) => {
  const { user } = useAuth();
  const widgets = useSelector((state) => state.app.widgets.filter( (w) =>
    ( w.default_tab === tabId ) && (w.type !== 'recommendations' || useCanManageRecomendationWidgetsFilter({ widget: w, user, location }))));
  const allWidgets = useSelector((state) => state.app.widgets);
  const dispatch = useDispatch();

  const handleVisibilityChange = (id, isVisible) => {
    const widget = allWidgets.find((w) => w.id === id);
    dispatch(updateWidget({ ...widget, visible: isVisible }));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(setIsValidDescriptiveScenario(true));
    dispatch(setWidgets(_.union(swap(widgets, oldIndex, newIndex), allWidgets)));
  };

  return (
    <SortableList
      items={widgets}
      onSortEnd={onSortEnd}
      handleVisibilityChange={handleVisibilityChange}
      groupId={tabId}
      useDragHandle
    />
  );
};

export default WidgetSort;
