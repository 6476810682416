import React from 'react';
import {
    Grid,
    Divider,
    MenuItem,
    Box,
    Typography,
} from '@material-ui/core';
import {
    SelectField,
  } from 'components/common/ReactHooksFormFields';
import TypographyWithInfoIconTooltip from 'components/common/TypographyWithInfoIconTooltip';
import {ReactSVG} from 'react-svg';
import { FormulaGenerator } from '../formula/FormulaGenerator';
import { useTranslation } from 'components/providers/TranslationProvider';
import CustomSkeleton from 'components/common/CustomSkeleton';
import {
  MuiGrid,
  sectionTitle,
  priorityVariable,
  flexAndBoxSizingContentBox,
  MuiSwitchCommon,
  infoIcon,
} from './styles/recommendations'

const RecommendationsDemand = ( props ) => {
  const {
    errors,
    onChangeLayerName,
    layers,
    demandDatasetName,
    isFormulaFieldSelected,
    setIsFormulaFieldSelected,
    setValue,
    isTargetDemandsFieldSelected,
    setIsTargetDemandsFieldSelected,
    tdform,
    wform,
    demandDatasetColumns,
    demandDatasetColumnsIsSuccess,
    setMessageToast,
    setSeverity,
  } = props;

  const { t } = useTranslation();

  return (
    <MuiGrid item xs={12} sx={{ paddingLeft: 0 }}>
                    <Grid container justifyContent='center'
                          sx={{alignContent: 'flex-start'}}>
                      <Grid container spacing={3} justifyContent='center'
                            directio={'row'}>
                          <Grid item container xs = {12}
                                justifyContent='space-between'
                                direction={'row'}>
                            <MuiGrid item xs={6}>
                              <div style={{ display: 'flex' }}>
                                <SelectField
                                  name='layer'
                                  error={t(errors.layer?.message)}
                                  variant='outlined'
                                  label={t('recomendation_demand_layer')}
                                  fullWidth
                                  InputLabelProps={{
                                    style: { color: '#424242' }
                                  }}
                                  onClick={(e) => onChangeLayerName(e.target.dataset.value)}
                                >

                                  <Divider light/>
                                  {layers?.map((l, index) => (
                                    <MenuItem key={l.id} value={l.id}
                                              data-cy={'layerDemand' + index}>
                                      <div style={{display:'flex', flexDirection:'row', pointerEvents: 'none'}} >
                                        <ReactSVG
                                          src={process.env.PUBLIC_URL + '/maki-icons/' + l.customIcon}
                                          afterInjection={(svg) => {
                                            svg.getElementsByTagName('path')[0]
                                              .setAttribute('fill', l.color);
                                          }}
                                          beforeInjection={(svg) => {
                                            svg.classList.add('svg-class-name')
                                          }} style={{width: 20 , height: 20, marginLeft:3 }}/>
                                        <span style={{marginLeft:4, marginTop:-2, pointerEvents: 'none'}}>{l.name}</span>
                                      </div>
                                    </MenuItem>
                                  ))}
                                </SelectField>
                                <TypographyWithInfoIconTooltip
                                  className={{
                                    marginLeft:'10px',
                                    marginTop:'15px'
                                  }}
                                  customBtnStyles={ infoIcon }
                                >
                                  {t('recommendation_layer_info')}
                                </TypographyWithInfoIconTooltip>
                              </div>
                            </MuiGrid>
                            <MuiGrid xs={6} style={{ display: 'flex' }}>
                                {demandDatasetColumnsIsSuccess ? (
                                  <>
                                    <SelectField
                                      name='densityVariable'
                                      variant='outlined'
                                      label={t('recommendations_demand_density')}
                                      fullWidth
                                      disabled={!demandDatasetName}
                                      error={t(errors.densityVariable?.message)}
                                    >
                                      <Divider light value=' '/>
                                      {demandDatasetColumns?.map((ddc) => (
                                        <MenuItem key={ddc} value={ddc}>
                                          {ddc}
                                        </MenuItem>
                                      ))}
                                    </SelectField>
                                    <TypographyWithInfoIconTooltip
                                      className={{
                                        marginLeft:'10px',
                                        marginTop:'15px'
                                      }}
                                      customBtnStyles={ infoIcon }
                                    >
                                      {t('recommendations_demand_density_info')}
                                    </TypographyWithInfoIconTooltip>
                                  </>

                                ) : (
                                  <CustomSkeleton style={{ marginTop: -16 }} />
                                )}

                            </MuiGrid>
                            <MuiGrid xs={12} >
                              <Box
                                display="flex"
                                alignItems="center"
                                sx={{
                                  marginBottom: 10,
                                  marginTop: 21,
                                }}
                              >
                                <Box display="flex" sx={{ alignItems: 'center' }}>
                                <Typography sx={sectionTitle}>{t('priority_variable_main_header')}</Typography>
                                <TypographyWithInfoIconTooltip
                                  customBtnStyles={{ backgroundColor: '#ffffff' }}>
                                        {t('recommendations_priorization_variable_info')}
                                      </TypographyWithInfoIconTooltip>
                                </Box>
                                <Typography sx={priorityVariable}>{t('recommendations_specify_welfare_formula')}</Typography>
                                <MuiSwitchCommon
                                  name='isFormulaFieldSelected'
                                  checked={isFormulaFieldSelected}
                                  onChange={(e) => {setIsFormulaFieldSelected(e.target.checked), setValue('calculator', e.target.checked)}}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              </Box>
                            </MuiGrid>
                            <MuiGrid item xs={12} sx={{marginTop: '25px'}}>
                              {isFormulaFieldSelected ? (
                                  <FormulaGenerator
                                        methodsForFormula={[wform.methods, wform.makeid, wform.setFormulaWithAlias, wform.setColumnListOrderFormula]}
                                        variablesForFormula={[[...wform?.columns,...(wform.definedParametersCatalog?.map(p => p.propName) || [])], wform.action, wform.isFormulaFieldSelected, wform.errors,
                                          wform.datasetColumn, wform.widget, wform.formulaWithAlias, wform.type, wform.alias, wform.columnsListOrder, 'recos_math', null, isFormulaFieldSelected
                                        ]}
                                        setValidFormula={wform.setValidFormula}
                                        formulaErrors={wform.formulaErrors}
                                        setFormulaErrors={wform.setFormulaErrors}
                                        formula={wform.formula}
                                        setFormula={wform.setFormula}
                                        tableName={wform.datasetName}
                                        setOpenToast={wform.setOpenToast}
                                        setIsFormulaValidate={wform.setIsFormulaValidate}
                                        setMessageToast={setMessageToast}
                                        setSeverity={setSeverity}
                                      />
                              ) : (
                                <>
                                  {demandDatasetColumnsIsSuccess ? (
                                    <div style={{ display: 'flex' }}>
                                      <SelectField
                                        name='welfareVariable'
                                        variant='outlined'
                                        label={t('variable_welfare')}
                                        fullWidth
                                        error={t(errors.welfareVariable?.message)}
                                      >
                                        {demandDatasetColumns?.map((ddc) => (
                                          <MenuItem key={ddc} value={ddc}>
                                            {ddc}
                                          </MenuItem>
                                        ))}

                                      </SelectField>
                                    </div>
                                  ) : (
                                    <CustomSkeleton style={{ marginTop: -16 }} />
                                  )}
                                </>
                              )}
                            </MuiGrid>
                            <MuiGrid
                              item xs={12}>
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 13,
                              }}>
                                <Box display="flex" alignItems="center">
                                <Typography>{t('demand_filters_main_header')}</Typography>
                                <TypographyWithInfoIconTooltip
                                        sx={{ display: 'auto' }}
                                        customBtnStyles={{ backgroundColor: '#ffffff' }}>
                                        {t('recommendations_demand_density_info')}
                                      </TypographyWithInfoIconTooltip>
                                </Box>
                                <MuiSwitchCommon
                                  name='isTargetDemandsFieldSelected'
                                  checked={isTargetDemandsFieldSelected}
                                  onChange={(e) => {setIsTargetDemandsFieldSelected(e.target.checked)}}
                                  inputProps={{'aria-label': 'controlled'}}
                                  color='primary'
                                />
                              </div>
                            </MuiGrid>
                            <MuiGrid item xs={12} sx={{marginTop: '25px'}}>
                              {
                                isTargetDemandsFieldSelected && (
                                <div style={flexAndBoxSizingContentBox}>
                                <FormulaGenerator
                                  methodsForFormula={[tdform.methods, tdform.makeid, tdform.setFormulaWithAlias, tdform.setColumnListOrderFormula]}
                                  variablesForFormula={[[...tdform.columns,...(tdform.definedParametersCatalog?.map(p => p.propName) || [])], tdform.action, tdform.isTargetDemandsFieldSelected, tdform.errors,
                                    tdform.datasetColumn, tdform.widget, tdform.formulaWithAlias, tdform.type, tdform.alias, tdform.columnsListOrder, 'recos_logic', isTargetDemandsFieldSelected, null
                                  ]}
                                  setValidFormula={ tdform.setValidFormula }
                                  formulaErrors={ tdform.formulaErrors }
                                  setFormulaErrors={ tdform.setFormulaErrors }
                                  formula={ tdform.targetDemandsFormula }
                                  setFormula={ tdform.setTargetDemandsFormula }
                                  tableName={ tdform.datasetName }
                                  setOpenToast={ tdform.setOpenToast }
                                  setIsFormulaValidate={tdform.setIsFormulaValidate}
                                  setMessageToast={setMessageToast}
                                  setSeverity={setSeverity}
                                />
                              </div>)
                            }
                            </MuiGrid>
                          </Grid>
                      </Grid>
                    </Grid>
                  </MuiGrid>
  );
};

export default RecommendationsDemand;
