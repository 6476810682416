import { Popover, styled, Typography } from '@mui/material';
import { GLOBAL } from 'utils/constants';
import { lighten } from '@mui/material/styles';

export const MuiPopover = styled(Popover)(({ theme }) => ({
  width: 200,
  minHeight: 120,
  '& .MuiPopover-paper': { display: 'inline-table' },
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
}));

export const MuiDiv = styled('div',{
  shouldForwardProp: (prop) => prop !== 'isValid' && prop !== 'isWidgetsPanelVisible'
})(({ theme,isWidgetsPanelVisible }) => ({
  backgroundColor: theme.palette.grey[50],
  position: 'relative',
  height: `calc(100% - ${theme.spacing(1)}px)`,
  width: isWidgetsPanelVisible ? `calc(100vw - 414px)` : '100vw',
  '@media (max-width: 600px)': {
    height: 'calc(100% - 95px)',
  },

  '@media (min-width: 600px)': {
    height: '100%',
    '& .mapboxgl-map, & #deckgl-overlay, & > div': {
      borderRadius: '4px',
    },
  },
}));

export const MuiTitleTooltip = styled(Typography)(({ theme }) => ({
  fontSize: '10px', 
  fontWeight: 700, 
  lineHeight: '12.19px', 
  letterSpacing: '0.025px',
  color: lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.5),
  marginBottom: '2px',
}));

export const MuiSubTitleTooltip = styled(Typography)(({ theme }) => ({
  fontSize: '8px', 
  fontWeight: 600, 
  lineHeight: '9.75px', 
  letterSpacing: '0.02px',
}));

export const MuiContentPropertyNameTooltip = styled(Typography)(({ theme }) => ({
  fontSize: 8, 
  fontWeight: 500, 
  color: GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[700], 
  lineHeight: '9.75px',
}));

export const MuiContentPropertyValueTooltip = styled(Typography)(({ theme }) => ({
  fontSize: 8, 
  fontWeight: 700, 
  lineHeight: '9.75px', 
  marginTop: '4px',
}));
