import { Grid, styled, TextField, Typography } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { GLOBAL } from '../../../../../../utils/constants';

export const MuiSubTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '24px',
  color: '#616161',
  fontFamily: 'Raleway',
  fontSize: 21,
  fontWeight: 500,
  letterSpacing: 0.15
}));

export const MuiTitle = styled(Typography)(({ theme }) => ({
  color: '#212121',
  marginBottom: '36px',
  fontFamily: 'Raleway',
  fontSize: '29px !important',
  fontWeight: 700,
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0.5, 0),
}));

export const MuiGridField = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 0, 1),
}));

export const MuiTextAreaField = styled(TextField)(({ theme }) => ({
  marginTop: '25px',
  marginLeft: '4.5rem !important',
  width: '332px',
  height: '370px',
  '& .MuiInputBase-root': {
    borderRadius: '12px',
    height: '100%',
    padding: '24px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  '& .MuiOutlinedInput-root': {
    height: '100%',
    width: '100%',
    '& textarea': {
      width: '100%',
      height: '100%',
      color: '#424242',
      fontSize: 16,
      fontFamily: 'Montserrat',
      lineHeight: 1.5,
      boxSizing: 'border-box',
      overflow: 'auto',
      resize: 'none',
    },
    '& fieldset': {
      borderColor: '#C0DBE5',
    },
    '&:hover fieldset': {
      borderColor: '#C0DBE5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#C0DBE5',
    },
  },
}));

export const iconButtonStyle = {
  marginTop: '-12px',
  width:'26px',
  height: '26px',
  '&:hover': {
    backgroundColor: lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.9),
  },
};

export const iconStyle = {
  width:'16px',
  height: '16px',
};

export const inputAdornmentStyle = {
  marginLeft:'-8px',
  width:'3px'
};

export const descriptionLabelStyle = {
  color: '#616161',
  marginTop: '5px',
  fontFamily: 'Raleway',
  fontSize: 16,
  fontWeight: 400,
  letterSpacing: 0.15
}

export const labelSelectStyle = {
  color: '#424242',
  fontSize:12,
  fontFamily:'Montserrat'
}
