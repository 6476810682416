import { GLOBAL } from 'utils/constants';
import { styled, Toolbar } from '@mui/material';
import { lighten } from '@mui/material/styles';

export const MuiToolbar = styled(Toolbar)(({ theme }) => ({
  margin: '0',
  width: '100%',
  zIndex: 1201,
}));

export const nonSelectedTab = {
  color: `${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800]} !important`,
};

export const muiTabs = {
  padding: 0,
  margin: 0,
  '& .Mui-selected': {
    fontSize: 14,
    fontWeight: 500,
    color: `${lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.8)} !important`,
    transition: 'font-size 10ms, font-weight 10ms',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: `${lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.9)} !important`,
    height: 3,
    borderRadius: '100px 100px 0px 0px',
  },
  '& .MuiTabs-scrollButtons': {
    '&.Mui-disabled': {
      display: 'none',
    },
  },
  '& .MuiTabs-scroller': {
    marginLeft: 0,
    marginRight: 0,
  },
}

export const muiTab = {
  minWidth: 'auto',
  paddingLeft: '10px',
  paddingRight: '10px',
  '&:hover': {
    backgroundColor: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[200],
    transition: 'background-color 0.3s',
    borderRadius: '12px 12px 0px 0px',
  },
}

export const tabStyle = {
  minWidth: '25%',
  fontWeight: 500,
  fontSize: 14,
  fontFamily:'Montserrat',
}


