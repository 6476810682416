/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import { Container, Grid, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'components/providers/TranslationProvider';
import WidgetsTabs from './WidgetsTabs';
import {
  MuiAddIcon,
  MuiCommonButtonAdd,
  MuiCommonTypographyAdd
} from '../../styles/common';
import {
  buttonStyle,
  MuiGridAddWidget,
  MuiGridFather,
  MuiGridWidget
} from './styles/widgets';

const Widgets = ({mapId}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = React.useState('');
  const tabs = useSelector((state) => state.app.tabs);
  const layers = useSelector((state) => state.app.layers);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <MuiGridFather container justifyContent='center'>
      <Grid container >
        <Grid item xs={3} style={{ marginBottom:'16px' }}>
          <Typography
            variant='subtitle1'
            fontSize={16}
            sx={{ marginLeft:'10px'}}
            color={'#616161'}
            marginTop='21px'
            letterSpacing={0.15}
            fontWeight={400}
            fontFamily={'Raleway'}
          >
            {t('tabs')}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", justifyContent: "end" }}>
          <MuiCommonButtonAdd
            sx={buttonStyle}
            variant='outlined'
            startIcon={<MuiAddIcon/>}
            disabled={layers.length < 1}
            onClick={() => navigate(`${location.pathname}?create=widgets-group`, { replace: true })}
            data-cy={'new_group'}
          >
            <MuiCommonTypographyAdd> {t('new_group')} </MuiCommonTypographyAdd>
          </MuiCommonButtonAdd>
        </Grid>
        <MuiGridWidget item xs={3}>
          <Typography
            variant='subtitle1'
            fontSize={16}
            sx={{ marginLeft:'10px'}}
            color={'#616161'}
            marginTop= '21px'
            letterSpacing={0.15}
            fontWeight={400}
            fontFamily={'Raleway'}
          >
            {t('widgets')}
          </Typography>
        </MuiGridWidget>
        <MuiGridAddWidget item xs={3}>
          <MuiCommonButtonAdd
            sx={{...buttonStyle, marginRight: '22px'}}
            variant='outlined'
            startIcon={<MuiAddIcon/>}
            disabled={layers.length < 1 || tabs.length < 1}
            onClick={() =>
              navigate(`${location.pathname}?create=widget&group-id=${activeTab}&map-id=${mapId}`, {
                replace: true,
              })}
            data-cy={'add_widget'}
          >
            <MuiCommonTypographyAdd> {t('new_widget')} </MuiCommonTypographyAdd>
          </MuiCommonButtonAdd>
        </MuiGridAddWidget>
      </Grid>
      <Container disableGutters style={{ height: 280 }}>
        <WidgetsTabs setTab={setActiveTab} />
      </Container>
    </MuiGridFather>
  );
};

export default Widgets;
