import * as React from 'react';
import {
  MenuItem,
  Grid,
  Divider,
  Typography
} from '@mui/material';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { TextField, SelectField } from 'components/common/ReactHooksFormFields';
import { useTranslation } from 'components/providers/TranslationProvider';
import useDataSetColumns from 'api/hooks/useDataSetColumns';
import Modal from 'components/common/Modal';
import { updateLayer } from 'store/appSlice';
import Toast from 'components/common/Toast';
import useRandomId from 'components/hooks/useRandomId';
import TransferListSort from '../../../common/TransferListSort';
import { MuiBtnAction, MuiBtnClose } from './styles/tooltipForm';
import {
  inputSelectStyle,
  inputStyle,
  labelInputStyle,
  labelSelectStyle
} from '../widget/form/styles/widgetForm';

const schema = yup.object().shape({
  layerId: yup.string().required('required'),
  name: yup.string().min(4, 'min_4_characters').max(80, 'max_80_characters').required('required'),
  label: yup.string(),
});

const TooltipForm = ({ action, open, onClose }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const randomId = useRandomId();

  const [options, setOptions] = React.useState([]);
  const [error, setError] = React.useState('');
  const [openToast, setOpenToast] = React.useState(false);

  const layers = useSelector((state) => state.app.layers);
  const tooltips = layers.reduce(
    (prevVal, current) =>
      current.tooltip?.name
        ? prevVal.concat({
            id: current.id,
            name: current.name,
            label: current.label,
            tooltip: current.tooltip,
            tooltipName: current.tooltip.name,
          })
        : prevVal.concat([]),
    []
  );

  const [newOrder, setNewOrder] = React.useState([]);
  const [realOrder, setRealOrder] = React.useState([]);
  const [changeDetectedShow, setChangeDetectedShow] = React.useState(false)
  const [changeDetectedOrder, setChangeDetectedOrder] = React.useState(false)
  const [changeDetectedName, setChangeDetectedName] = React.useState(false)
  const [rigth,setRigth ]= React.useState([]);

  const handleSetItem = (value) => {
    setValue('columns', value);
    setNewOrder(value);
  }
  const handleOrder = (value) => {
    setRigth(value);
    if (action === 'update')  setChangeDetectedOrder(true);
    else setChangeDetectedOrder(false);
  }

  const layersAvailable = _.differenceBy(layers, tooltips, 'id');

  const tooltip = location.state;
  const getDefaultValues = () => {
    if (tooltip) {
      return {
        id: tooltip?.id,
        layerId: tooltip?.layerId,
        name: tooltip?.name,
        label: tooltip?.label || '',
        columns: tooltip?.columns || [],
      };
    } else {
      return {};
    }
  };

  React.useEffect(() => {
    setRealOrder(newOrder);
  }, [newOrder]);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    setValue,
  } = methods;

  const selectedLayerId = watch('layerId');
  const { data: columns, isSuccess } = useDataSetColumns(
    layers?.filter((l) => l.id ===selectedLayerId)[0]?.datasetName
  );

  const findRepeatNames = (name, id) => {
    const foundTooltipsName = _.find(tooltips, { tooltipName: name });
    if (foundTooltipsName?.tooltip.id === id) return false;
    return foundTooltipsName;
  };

  const newTooltip = (data) => {
    if (findRepeatNames(data.name, tooltip?.id)) {
      setError(t('error_adding_new_tooltip'));
      setOpenToast(true);
      return;
    }
    const layer = layers.filter((l) => l.id === selectedLayerId)[0];
    const newLayer = {
      ...layer,
      tooltip: {
        id: randomId,
        ...data,
        columns: rigth
      },
    }
    dispatch(
      updateLayer(newLayer)
    );
    onClose();
    setValue('layerId', ' ');
  };

  const handleCloseToast = () => setOpenToast(false);

  const onSubmit = handleSubmit((data) => newTooltip(data));

  React.useEffect(() => {
    if (selectedLayerId && isSuccess) {
      const fieldOptions = columns.filter((f) => f !== 'the_geom_webmercator').map((c) => {
        return c;
      });
      setOptions(fieldOptions);
      setValue('columns', []);
    }
  }, [selectedLayerId, isSuccess, setValue, columns]);

  React.useEffect(() => {
    if (isSuccess && columns){
      let colNotSelected = columns.map(str => ({ field: str, name: str , show : false, id: Date.now().toString(36) + Math.random().toString(36)}));
      const colSelected = tooltip?.columns.map(str => ({ ...str ,show : true}));
      colNotSelected = colNotSelected.filter(obj1 =>
        !tooltip?.columns.some(obj2 => obj2.field === obj1.field)
      );
      setRealOrder(colNotSelected)
      if (action === 'update') {
        setRigth(colSelected)
      }
    }
  }, [tooltip?.columns, isSuccess, columns, options]);

  const actions = (
    <div style={{marginTop:30}}>
      <MuiBtnClose onClick={onClose} color='primary' variant='text'>
        <Typography
          variant='body2'
          letterSpacing={0.25}>
          {t('cancel_btn')}
        </Typography>
      </MuiBtnClose>
      <MuiBtnAction
        onClick={onSubmit}
        variant='outlined'
        color='primary'
        loading={false}
        disabled={!isValid || (changeDetectedShow || changeDetectedOrder || changeDetectedName ? isDirty : !isDirty) }
      >
        <Typography
          variant='body2'
          letterSpacing={0.25}
        >
          {t('save_changes').toUpperCase()}
        </Typography>
      </MuiBtnAction>
    </div>
  );

  const tooltipForm = (
    <Grid container>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      <Grid container display={'flex'} justifyContent={'space-between'}>
        <Grid item style={{width:'355px'}}>
          <TextField
            name='name'
            margin='dense'
            type='text'
            label={t('tooltip_name')}
            variant='outlined'
            fullWidth
            error={t(errors.name?.message)}
            InputLabelProps={{ style: labelInputStyle }}
            sx={inputStyle}
          />
        </Grid>
        <Grid item style={{width:'355px'}}>
          <TextField
            name='label'
            margin='dense'
            type='text'
            label={t('tooltip_label')}
            variant='outlined'
            fullWidth
            error={t(errors.label?.message)}
            InputLabelProps={{ style: labelInputStyle }}
            sx={inputStyle}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 0 }}>
          <SelectField
            name='layerId'
            error={t(errors.layerId?.message)}
            variant='outlined'
            label={t('select_layer')}
            fullWidth
            disabled={action === 'update'}
            InputLabelProps={{ style: labelSelectStyle }}
            sx={{...inputSelectStyle, paddingBottom:'0px !important'}}
          >
            <Divider light />
            {action === 'create' &&
              layersAvailable?.map((l) => (
                <MenuItem key={l.id} value={l.id}>
                  {l.name}
                </MenuItem>
              ))}
            {action === 'update' &&
              layers.map((l) => (
                <MenuItem key={l.id} value={l.id}>
                  {l.name}
                </MenuItem>
              ))}
          </SelectField>
        </Grid>
      </Grid>
      {selectedLayerId && (
        <TransferListSort
          items={realOrder}
          right={rigth}
          setItems={handleSetItem}
          setRight={setRigth}
          setOrderSelection={handleOrder}
          action={action}
          changeDetectedShow={setChangeDetectedShow}
          changeDetectedName={setChangeDetectedName}
          changeDetectedOrder={setChangeDetectedOrder}
          widthSearchInPixel={190}
          leftColumnsWidthInPixel={200}
          rightColumnsWidthInPixel={420}
          keyLabel={'columns_show_tooltip'}
        />
      )}
    </Grid>
  );

  return (
    <FormProvider {...methods}>
      <form style={{ width: '100%' }}>
        <Modal
          open={open}
          onClose={onClose}
          title={action === 'update' ? `${t('update')} ${tooltip?.name}` : `${t('new_tooltip')}`}
          actions={actions}
          widthInPixels={760}
        >
          {tooltipForm}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default TooltipForm;
