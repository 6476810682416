import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';
import { setMessageToast, setOpenToast } from 'store/appSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'components/providers/TranslationProvider';

const fetchDataSetColumns = (dataSet, filter=undefined, withType=false) => {
  if (dataSet) {
    return axios.post(`${apiPaths.dataSetCarto}/${dataSet}/columns`, filter,
      status200).then((resp) => {
      if(withType){
       return resp.data.value
      }
      else {
      let columnNames = resp.data.value.map( (metaColumns) => metaColumns.name );
      return columnNames;
      }
    });
  }
  return [];
}


const useDataSetColumns = (dataSet, filter=undefined, withType= false) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return useQuery(
    ['columns', dataSet], 
    () => fetchDataSetColumns( dataSet, {filter} , withType),
    {
      onError: (error) => {
        dispatch(setOpenToast(true));
        dispatch(setMessageToast(t(error.data.error)));
      }
    }
  );
}

export default useDataSetColumns;