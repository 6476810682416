import {
  useEffect
} from 'react';
import {
    Grid,
    Divider,
    MenuItem,
    Box,
    Typography,
    Switch,
} from '@material-ui/core';
import {
    SelectField,
    SliderFieldCustom,
  } from 'components/common/ReactHooksFormFields';
import TypographyWithInfoIconTooltip from 'components/common/TypographyWithInfoIconTooltip';
import {ReactSVG} from 'react-svg';
import styles from './styles/recommendations';
import useDataSetColumns from 'api/hooks/useDataSetColumns';
import { useTranslation } from 'components/providers/TranslationProvider';
import { switchCommonStyles } from '../styles/category';
import CustomSkeleton from 'components/common/CustomSkeleton';
import {flexbox} from '@mui/system';

import {
  typographyWithInfoIconTooltipStyles,
  selectSkeleton,
  MuiGrid,
  sliderControl,
  MuiSwitchCommon,
  infoIcon,
} from './styles/recommendations';

const RecommendationsServicePoint = ( props ) => {
    const {
        errors,
        layers,
        setValue,
        offerCoverageRadiusHandleChange,
        isOfferSelected,
        dataset,
        selectedLayer,
        isPotentialOffersDatasetSelected,
        currentOfferDatasetName,
        demandDatasetColumnsIsError,
        showMessage,
        isOfferCapacitySelected,
        filter,
        potentialOffersDatasetName
      } = props;

    const { t } = useTranslation();

    const {
      data: currentOfferDatasetColumns,
      isSuccess: currentOfferDatasetColumnsIsSuccess,
      isError: currentOfferDatasetColumnsIsError
    } = useDataSetColumns(
      currentOfferDatasetName,
      filter
    );

    useEffect(() => {
      if (demandDatasetColumnsIsError || currentOfferDatasetColumnsIsError) {
        showMessage(t('incluia_error_getting_dataset_columns_from_carto'), 'error');
      }
    }, [demandDatasetColumnsIsError, currentOfferDatasetColumnsIsError]);

    useEffect(() => {
      if (potentialOffersDatasetName) {
        const potentialOffersLayerId = layers.find( l => l.datasetName === potentialOffersDatasetName).id
        setValue('potentialOffersDataset', potentialOffersLayerId);
      }
    }, []);

    return (
        <MuiGrid item xs={12}>
                    <Grid container justifyContent='center'
                          style={{alignContent: 'flex-start'}}>
                      <Grid container spacing={3} justifyContent='center'
                            directio={'row'}>
                        <MuiGrid item xs={12}>
                          <Grid item container
                                justifyContent='space-between'
                                direction={'row'}>
                            <Grid xs={6}
                                  sx={{display: 'flex', ...sliderControl(flexbox)}} style={{ paddingRight: '12px' }}>
                              <Grid item xs={12} sx={{marginRight: 16}}>
                                  <Box flexDirection= 'column'>
                                    <Box display="flex" alignItems="center">
                                      <Typography variant='caption'
                                      >{t('coverage_radius')}(m)</Typography>
                                      <TypographyWithInfoIconTooltip customBtnStyles={ infoIcon }>
                                        {t('recommendations_offer_coverage_radius')}
                                      </TypographyWithInfoIconTooltip>
                                    </Box>
                                  <SliderFieldCustom
                                    name='offerCoverageRadius'
                                    defaultValue={200}
                                    min={200}
                                    customMax={20000}
                                    valueLabelDisplay='auto'
                                    custom
                                    gridSliderInputColumn= {2}
                                    gridSliderColumn= {10}
                                    customOnChange={offerCoverageRadiusHandleChange}
                                    isConfigRecomendationsForm={true}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <MuiGrid item xs={6}
                                  sx={{ ...sliderControl(flexbox), display: 'flex' }}>
                              <Grid item xs={12} style={{marginRight: '16px'}}>
                                <Box flexDirection= 'column'>
                                  <Box display="flex" alignItems="center">
                                    <Typography
                                      variant='caption'
                                    >
                                      {t('cost')}
                                    </Typography>
                                    <TypographyWithInfoIconTooltip customBtnStyles={ infoIcon }>
                                      {t('recommendations_offer_cost')}
                                    </TypographyWithInfoIconTooltip>
                                  </Box>
                                  <SliderFieldCustom
                                    name='offerCost'
                                    defaultValue={1}
                                    step={1}
                                    min={1}
                                    max={100}
                                    valueLabelDisplay='auto'
                                    custom
                                    gridSliderInputColumn= {2}
                                    gridSliderColumn= {10}
                                    isConfigRecomendationsForm={true}
                                  />
                                </Box>
                              </Grid>
                            </MuiGrid>
                            <Grid item container
                                  justifyContent='space-between'
                                  direction={'row'}
                                  sx={{ marginBottom: '42px' }}
                            >
                              <MuiGrid xs={6}
                                    direction={'column'}
                                    sx={{ marginTop: '36px', marginBottom: '15px', display: 'flex' }}
                                >
                                <Box display="inline-flex" sx={{ marginBottom: 16 }} alignItems="center">
                                  <Typography sx={{ marginRgth: 12 }}> {t('recommendations_specify_offer')} </Typography>
                                  <MuiSwitchCommon
                                    name='isOfferSelected'
                                    checked={isOfferSelected}
                                    onChange={(e) => setValue("isOfferSelected", e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                </Box >
                                { isOfferSelected &&
                                  (<Box display="flex">
                                  <Box display="flex" width="100%">
                                    {
                                      dataset.isSuccess ? (
                                        <SelectField
                                              name='currentOfferLayerName'
                                              variant='outlined'
                                              label={t('current_offer')}
                                              fullWidth
                                              error={t(errors.currentOfferLayerName?.message)}
                                              InputLabelProps={{
                                                style: { color: '#424242' }
                                              }}
                                            >
                                              <Divider light value=' '/>
                                              {layers?.filter((l) => l.id !== selectedLayer).map((l) => (
                                                <MenuItem key={l.id} value={l.id}>
                                                  <div style={{display:'flex', flexDirection:'row'}} >
                                                    <ReactSVG
                                                      src={process.env.PUBLIC_URL + '/maki-icons/' + l.customIcon}
                                                      afterInjection={(svg) => {
                                                        svg.getElementsByTagName('path')[0]
                                                          .setAttribute('fill', l.color);
                                                      }}
                                                      beforeInjection={(svg) => {
                                                        svg.classList.add('svg-class-name')
                                                      }} style={{width: '20px' , height: '20px', marginLeft: 3 }}/>
                                                    <span style={{marginLeft:4, marginTop:-2}}>{l.name}</span>
                                                  </div>
                                                </MenuItem>
                                              ))}
                                              <MenuItem key="none" value="none">
                                                <div style={{display:'flex', flexDirection:'row'}} >
                                                  <span style={{marginLeft:4, marginTop:-2}}>{ t('none').toUpperCase() }</span>
                                                </div>
                                              </MenuItem>
                                            </SelectField>
                                          ) : (
                                            <CustomSkeleton
                                              className={selectSkeleton}/>
                                    )}
                                    <TypographyWithInfoIconTooltip
                                      className={typographyWithInfoIconTooltipStyles} customBtnStyles={ infoIcon }>
                                      {t('current_offers_layer_stored_info')}
                                    </TypographyWithInfoIconTooltip>
                                    </Box>
                                </Box>)
                                }
                              </MuiGrid>
                              <Grid xs={6}>
                                <Box display="inline-flex" sx={{ marginBottom: '16px', marginTop: '36px', }} alignItems="center">
                                  <Typography sx={{ marginRight: '12px' }}> {t('recomendations_specify_potential_offers_dataset')} </Typography>
                                  <MuiSwitchCommon
                                    name='isPotentialOffersDatasetSelected'
                                    checked={isPotentialOffersDatasetSelected}
                                    onChange={(e) => setValue("isPotentialOffersDatasetSelected", e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                </Box>

                                {isPotentialOffersDatasetSelected && (
                                  <Box display ="flex">
                                    <Box display="flex" width="100%">
                                    {dataset.isSuccess ? (
                                      <SelectField
                                        name='potentialOffersDataset'
                                        variant='outlined'
                                        label={t('recomendations_potential_offers_dataset')}
                                        fullWidth
                                        error={t(errors.potentialOffersDataset?.message)}
                                        InputLabelProps={{
                                          style: { color: '#424242' }
                                        }}
                                      >
                                        <Divider light value=' '/>
                                        {layers?.filter((l) => l.id !== selectedLayer).map((l) => (
                                          <MenuItem key={l.id} value={l.id}>
                                            <div style={{display:'flex', flexDirection:'row'}} >
                                              <ReactSVG
                                                src={process.env.PUBLIC_URL + '/maki-icons/' + l.customIcon}
                                                afterInjection={(svg) => {
                                                  svg.getElementsByTagName('path')[0]
                                                    .setAttribute('fill', l.color);
                                                }}
                                                beforeInjection={(svg) => {
                                                  svg.classList.add('svg-class-name')
                                                }} style={{width: 20 , height: 20, marginLeft:3 }}/>
                                              <span style={{marginLeft:4, marginTop:-2}}>{l.name}</span>
                                            </div>
                                          </MenuItem>
                                        ))}
                                        <MenuItem key="none" value="none">
                                          <div style={{display:'flex', flexDirection:'row'}} >
                                            <span style={{marginLeft:4, marginTop:-2}}>{ t('none').toUpperCase() }</span>
                                          </div>
                                        </MenuItem>
                                      </SelectField>
                                    ) : (
                                      <CustomSkeleton
                                        className={selectSkeleton}/>
                                    )}
                                    <TypographyWithInfoIconTooltip
                                      className={typographyWithInfoIconTooltipStyles} customBtnStyles={ infoIcon }>
                                      {t('potential_offers_dataset_info')}
                                    </TypographyWithInfoIconTooltip>
                                  </Box>
                                  </Box>
                              )}
                              </Grid>
                            </Grid>
                            <MuiGrid item xs={6}>
                                    <Box width="100%" flexDirection="column" display="flex">
                              <Box width="100%">
                                <Box  display="flex" alignItems="center" sx={{ marginBottom: 16 }}>
                                  <Typography sx={{ marginBottom: 24, marginRight: 12 }}>{t('recommendations_is_offer_capacity_selected')}</Typography>
                                  <MuiSwitchCommon
                                    name='isOfferCapacitySelected'
                                    checked={isOfferCapacitySelected}
                                    onChange={(e) => setValue("isOfferCapacitySelected", e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                </Box>
                                {isOfferCapacitySelected && (
                                <Box display="flex" flexDirection="row">
                                    <Box display="flex" width="100%">
                                      {currentOfferDatasetColumnsIsSuccess ? (
                                        <SelectField
                                          name='capacityVariable'
                                          variant='outlined'
                                          label={t('capacity_variable')}
                                          fullWidth
                                          disabled={!currentOfferDatasetName}
                                          style={{ paddingTop: 0 }}
                                        >
                                          <Divider light value=' '/>
                                          {currentOfferDatasetColumns?.map((codc) => (
                                            <MenuItem key={codc}
                                                      value={codc}>
                                              {codc}
                                            </MenuItem>
                                          ))}
                                          <MenuItem key="none" value="none">
                                          <div style={{display:'flex', flexDirection:'row'}} >
                                            <span style={{marginLeft:4, marginTop:-2}}>{ t('none').toUpperCase() }</span>
                                          </div>
                                        </MenuItem>
                                        </SelectField>
                                      ) : (
                                        <CustomSkeleton
                                          className={selectSkeleton}/>
                                      )}
                                      <TypographyWithInfoIconTooltip
                                        className={typographyWithInfoIconTooltipStyles} customBtnStyles={ infoIcon }>
                                        {t('recommendations_offer_capacity_dataset')}
                                      </TypographyWithInfoIconTooltip>
                                    </Box>
                                </Box>
                                )}
                              </Box>
                              <Box width="100%">
                                {isOfferCapacitySelected && (
                                  <Box marginTop="23px">
                                    <Box display="flex" alignItems="center">
                                    <Typography
                                      >{t('recommendations_offer_capacity')}</Typography>
                                      <TypographyWithInfoIconTooltip customBtnStyles={ infoIcon }>
                                        {t('recommendations_offercapacity_tooltip')}
                                      </TypographyWithInfoIconTooltip>
                                      </Box>
                                    <Box display="flex">
                                      <SliderFieldCustom
                                        name='offerCapacity'
                                        defaultValue={0}
                                        step={1}
                                        min={1}
                                        max={1000}
                                        valueLabelDisplay='auto'
                                        custom
                                        gridSliderInputColumn= {2}
                                        gridSliderColumn= {10}
                                        isConfigRecomendationsForm={true}
                                      />
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                              </Box>
                            </MuiGrid>
                          </Grid>

                        </MuiGrid>
                      </Grid>
                    </Grid>
                  </MuiGrid>
    );
};

export default RecommendationsServicePoint;
