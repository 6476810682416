import {
  Button,
  Card,
  CardActionArea,
  Container,
  Grid,
  styled,
  Typography,
  IconButton
} from '@mui/material';
import { hexToRgb } from '../../../../../utils/colorConverter';
import { GLOBAL } from '../../../../../utils/constants';
import { lighten } from '@mui/material/styles';

export const MuiMaterialCard = styled(Card,  {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'disabled',
})(({ theme,active, disabled  }) => ({
  width: 150,
  height: 150,
  cursor: disabled ? 'auto' : 'pointer',
  borderStyle: 'solid',
  borderWidth: active && !disabled ? '2px' : '1px',
  borderColor: active ? theme.palette.primary.dark : theme.palette.grey[300],
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  margin: theme.spacing(1),
}));

export const MuiCardContent = styled(CardActionArea,  {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'disabled',
})(({ theme,active, disabled  }) => ({
  cursor: disabled ? 'auto' : 'pointer',
  background: active && `rgba(${hexToRgb(localStorage.getItem('colorPrimary')).join(', ')},0.2)`,
  display: 'flex',
  justifyContent: 'center',
}));

export const MuiGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'start',
  alignContent: 'center',
  marginBottom: 25
}));

export const MuiBtnClose = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]
}));

export const MuiBtnAction = styled(IconButton)(({ theme }) => ({
  padding:'10px 16px 10px 16px',
  borderRadius: '20px',
  color:'#005F64',
  border:'1px solid',
  borderColor:'#005F64',
  height:'37px',
  width:'105px',
  backgroundColor: lighten('#005F64', 1),
  '&:hover': {
    borderColor:'#005F64',
    backgroundColor: lighten('#005F64', 0.8),
  },
  fontWeight: 500,
  fontSize: '14px',
  fontFamily: 'Montserrat',
}));

export const MuiContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const MuiTitle = styled(Typography)(({ theme }) => ({
  marginTop: 0,
  fontSize: 12,
}));

export const iconsCommonStyle = {
  width: 100,
  height: 100,
  color: localStorage.getItem('colorPrimary'),
  fontSize: 40,
}
