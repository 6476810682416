import { Grid, styled } from '@mui/material';

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  padding:1,
  marginTop:'7px',
}));

export const MuiGridDrag = styled(Grid)(({ theme }) => ({
  width: '45px',
  alignItems: 'center',
  display: 'flex',
  marginTop:'-10px',
  justifyContent: 'center'
}));

export const MuiGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop:'-10px'
}));

export const MuiGridSorteableList = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  alignContent: 'flex-start',
  overflow: 'auto',
  paddingLeft: '10px',
  paddingRight: '10px',
}));

export const MuiGrid = styled(Grid)(({ theme }) => ({
  marginLeft: 0,
  display: 'contents',
}));

export const MuiContainerIcons = styled("div")(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '19.1rem'
}));

export const commonIconsWidgetStyle = {
  color:'#FFFFFF',
  padding:'2px',
  borderRadius:'50%',
  marginRight:'5px'
}

export const dragHandleStyle = {
  cursor: 'row-resize',
  marginRight: 8,
  color:'#757575'
}
export const boxStyle = {
  height: 20,
  width: 20,
  marginRight: 8,
}
