import * as React from 'react';
import { Grid, Paper } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { MuiDivLayout, MuiGrid, MuiGridLayout } from './styles/login';
import LanguageSelector from 'components/common/LanguageSelector';

const LoginLayout = () => {

  return (
    <MuiGridLayout container component='main'>
      <Grid item xs={false} sm={false} md={7} sx={{ maxWidth: '100% !important', flexGrow: '1 !important' }}>
        <MuiDivLayout/>
      </Grid>
      <Grid item xs={12} sm={12} md={5} component={Paper} sx={{height: 'inherit', width: '556px !important', maxWidth: '556px !important'}}>
        <MuiGrid>
          <LanguageSelector />
        </MuiGrid>
        <Outlet />
      </Grid>
    </MuiGridLayout>
  );
};

export default LoginLayout;
