import * as React from 'react';
import { MenuItem, Divider, InputAdornment, Select } from '@mui/material';

import { useTranslation } from 'components/providers/TranslationProvider';
import {
  MuiExpandMoreIcon,
  MuiInputBase as BootstrapInput,
} from './styles/languageSelector';
import { useLocation } from 'react-router-dom';
import { GLOBAL } from 'utils/constants';

const LanguageSelector = () => {
  const { languages, lng, changeLanguage } = useTranslation();
  const location = useLocation();
  const [isLoginView, setIsLoginView] = React.useState(false);

  React.useEffect(() => {
    const loginRoutes = ['/login', '/forgot-password', '/reset'];
    setIsLoginView(loginRoutes.includes(location.pathname));
  }, [location.pathname]);

  return (
    <Select
      value={lng}
      data-cy={'language'}
      onChange={(e) => changeLanguage(e.target.value)}
      input={<BootstrapInput />}
      IconComponent={(props) => <MuiExpandMoreIcon style={{ color:  isLoginView ? 'black' : 'white' }} {...props} />}
      startAdornment={
        <InputAdornment position="start">
          <img style={{height:'18px', width:'18px'}} src={`/countries/${lng}.png`}/>
        </InputAdornment>
      }
      style={{ color:  isLoginView ? GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[900] : 'white' }}

    >

      <Divider variant='inset' />
      {languages?.map((l) => (
        <MenuItem color= { isLoginView ? GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[900] : 'white' } key={l} disabled={l === lng} value={l}>
          {l}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSelector;
