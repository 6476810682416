/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useRef, useState, Fragment} from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {useDispatch, useSelector} from 'react-redux';
import {
  Avatar,
  Box, Collapse, Divider,
  InputAdornment,
  List,
  ListItem, ListItemAvatar,
  ListItemIcon, ListItemText,  TablePagination,
  TextField
} from "@mui/material";
import SearchIcon
  from "../../../../lib/react-geo-tool/packages/react-ui/src/assets/icons/SearchIcon";
import {SystemUpdateAlt, Tune} from "@mui/icons-material";
import FeatureSelectionWidget from "../selection/FeatureSelectionWidget";
import DrawIcon from "@mui/icons-material/Draw";
import CircleIcon from '@mui/icons-material/Circle';
import {
  AccessTime,
  DirectionsBike,DirectionsCar,
  DirectionsWalk, KeyboardArrowDown, SquareFoot
} from "@mui/icons-material";

import {
  deleteLayerIsochrones, setAsDeletedPolygon, setListPolygonWidgetForDelete,
  setPolygonDataLoading
} from "../../../../store/appSlice";
import useDataIsochrone from "../../../../api/hooks/useDataIsocrona";
import {useTranslation} from "../../../providers/TranslationProvider";
import DeleteIsochroneModal from "./DeleteIsochroneModal";
import FilterPopoverPolygon from "./FilterPopoverPolygon";
import {formatNumber} from "../../../../utils/formatter";
import {
  body1,
  body2,
  containerStyle,
  list,
  MuiCheckbox,
  MuiDeleteIcon,
  MuiDivRoot,
  MuiFilterListIcon,
  MuiIconButton,
  MuiList,
  overline,
  paginationStyle,
  search, searchIconStyle
} from '../styles/polygonWidgetUI';

function PolygonWidgetUI(props) {
  const {t} = useTranslation();

  const {
    data,
    onSelect,
    id,
    updateListForDelete,
    handleFilterPolygon,
    updateAsDeletePolygon,
    newColumns,
    polygonDisplayCol
  } = props;

  const dataRows = (data?.values || []).map((item, index) => ({
    ...item,
    index
  }));
  const [filteredData, setFilteredData] = useState([]);
  const [searchFilteredData, setSearchFilteredData] = useState([]);
  const newCols = newColumns.map((c) => c.field)
  const [ viewSelected, setViewSelected ] = React.useState(null);
  const [selectionModel, setSelectionModel] = React.useState({ [props.id]: [] });
  const deletedPolygon = useSelector((state) => state.app.deletedPolygon);
  const filterPolygon = useSelector((state) => state.app.listPolygonWidgetForDelete);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDel] = useState(false);
  const [openFilter, setOpenFilter]= useState(false);
  const filterButtonRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();

  const dispatchSetPolygonDataLoading = (data) => {
    dispatch(setPolygonDataLoading(data));
  }
  const {
    mutateAsync: DeleteIsochrone,
    isSuccess: isSuccessSaveIsochrone,
    isError: isErrorSaveIsochrone,
    error: errorSaveIsochrone,
    isLoading: isLoadingSaveIsochrone,
  } = useDataIsochrone(null, dispatchSetPolygonDataLoading);


React.useEffect(()=> {
  if (deletedPolygon) {
    setSelectionModel({ [props.id]: [] });
    updateAsDeletePolygon(false);
  }
}, [deletedPolygon]);

  React.useEffect(()=> {
    if (data) {
     setFilteredData(dataRows);
    }
  }, [data]);

  React.useEffect(()=> {
    setSearchFilteredData(filteredData)
  },[filteredData])


React.useEffect(()=> {
  if (Object.keys(filterPolygon).length >0 && selectionModel?.length ==0) {
    const selectedIndex = filterPolygon[props.id]?.map(objSeleccionado => {
      return dataRows.findIndex(item => item.polygon_id === objSeleccionado.polygon_id);
    });
    setSelectionModel({ [props.id]: selectedIndex });
  } else if (Object.keys(filterPolygon).length ==0 && selectionModel?.length >0) {
    setSelectionModel({ [props.id]: [] });
  }
}, [filterPolygon]);

  const getIcon = (item) => {
    switch (item.polygon_type) {
      case 'drawn':
        return <DrawIcon />;
      case 'isochrone':
        return item.mode === 'pedestrian'?  <DirectionsWalk /> : item.mode === 'bicycle' ?
                <DirectionsBike/> : <DirectionsCar/> ;
      default:
        return <SystemUpdateAlt />;
    }
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleListItemClick = (id) => {
    if (selectedItemId === id) {
      setOpen(!open);
    } else {
      setOpen(true);
      setSelectedItemId(id);
    }
  };
  const handleToggle = (id) => () => {
    const currentSelection = selectionModel[props.id] || [];
    const currentIndex = currentSelection.indexOf(id);
    let newChecked = [...currentSelection];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectionModel(prevState => ({
      ...prevState,
      [props.id]: newChecked
    }));
    updateListForDelete(newChecked);
  };
  const handleDeleteModal = () => {
    setOpenDel(true);
  }
  const onClose = () => {
    setOpenDel(false)
  }
  const onConfirm = async () => {
    const listToDelete = filterPolygon[props.id];
    await DeleteIsochrone({type: 'delete', iscData: listToDelete});
    const idsDelete = listToDelete.map(item => item.polygon_id);
    dispatch(deleteLayerIsochrones(idsDelete));
    dispatch(setListPolygonWidgetForDelete({id:props.id, selection:[]}));
    dispatch(setAsDeletedPolygon(true));
    setOpenDel(false);
  }

  const showFilterPopover = () => {
    setOpenFilter(true);
  }
  const onCloseF = (val) =>{
    setOpenFilter(val)
  }
  const handleFilter = (propData) => {
    const { polygonType, areaRange } = propData;
    const newData = dataRows?.filter(item => {
      const isTypeMatch = polygonType.length > 0 ? polygonType.includes(item.polygon_type) : true;
      const isAreaMatch = item.area >= areaRange[0] && item.area <= areaRange[1];
      return isTypeMatch && isAreaMatch;
    });
    setFilteredData(newData);
    setOpenFilter(false);
  }

  const getMinValue = Math.min(...dataRows?.map(item => item.area));
  const getMaxValue = Math.max(...dataRows?.map(item => item.area)) || 2000;

  const handleSearchChange = (event) => {
    const term= event.target.value;
    const dataT = [...filteredData]
    const newData = dataT.filter(row => {
      return Object.keys(row).some(key =>
        String(row[key]).toLowerCase().includes(term.toLowerCase())
      );
    });
    setSearchTerm(term)
    setSearchFilteredData(newData);
  };

  return (
    <MuiDivRoot>
      <DeleteIsochroneModal
        open={openDelete}
        onClose={onClose}
        onConfirm={onConfirm}
        count={filterPolygon[props.id]?.length}
      />
      <FilterPopoverPolygon
        open={openFilter}
        onClose={onCloseF}
        handleFilter={handleFilter}
        anchorEl={filterButtonRef.current}
        min={getMinValue}
        max={getMaxValue}
      />
      <Box sx={{ display: 'flex', justifyContent:'space-between', alignItems: 'left'}}>
        <TextField
          variant="outlined"
          placeholder={t('search')}
          sx={search}
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
          startAdornment: (
            <InputAdornment position='start' sx={{ width: '32px', height: '32px' }}>
              <SearchIcon style={searchIconStyle}/>
            </InputAdornment>
          ),
            endAdornment: (
              <InputAdornment position='end'>
                <Tune
                  style={{color: '#424242', height: '18px'}}
                  ref={filterButtonRef}
                  onClick={showFilterPopover}
                />
              </InputAdornment>
            )
        }}/>
        {Object.keys(filterPolygon).length >0 && selectionModel[props.id]?.length > 0 &&(
          <>
            <MuiIconButton
              onClick={handleFilterPolygon}>
              <MuiFilterListIcon/>
            </MuiIconButton>
            <MuiIconButton
              onClick={handleDeleteModal}>
              <MuiDeleteIcon/>
            </MuiIconButton>
          </>
          )}
        {(!selectionModel[id] || selectionModel[id].length === 0) && (
          <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <FeatureSelectionWidget id={id}/>
          </Box>
        )}
      </Box>
      <Box sx={containerStyle}>
      <Box sx={list}>
          <MuiList
             sx={{ width: '100%'}}
             component="nav"
           >
             {searchFilteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
               <Fragment key={item.polygon_id}>
               <ListItem key={item.polygon_id} sx={{height: '52px' , maxWidth: '406px'}}>
                 <ListItemIcon
                   onClick={handleToggle(item.index)}>
                   <MuiCheckbox
                    checked={ selectionModel[props.id]?.includes(item.index) }
                   />
                 </ListItemIcon>
                 <ListItemIcon>
                   <VisibilityIcon
                     style={{
                       color: item.polygon_id === viewSelected ?
                         localStorage.getItem('colorPrimary') : 'gray',
                     }}
                     onClick={() => {
                               setViewSelected(item.polygon_id);
                               onSelect(item.geom);
                             }}/>
                 </ListItemIcon>
                 <ListItemAvatar> <Avatar>{getIcon(item)}</Avatar></ListItemAvatar>
                 <ListItemText  primary={ item[polygonDisplayCol] || formatNumber(item.area)+ ' (m2)'}
                                primaryTypographyProps={body1}/>
                 {item.polygon_type !== 'drawn' && (
                   <KeyboardArrowDown
                     sx={{
                       transform: open && selectedItemId === item.polygon_id ? 'rotate(-180deg)' : 'rotate(0)',
                       transition: '0.2s',
                       mr: '10px'
                     }}
                     onClick={(event) => {
                       event.stopPropagation();
                       handleListItemClick(item.polygon_id);
                     }}
                   />
                 )}

               </ListItem>
                 <Collapse in={open && selectedItemId === item.polygon_id} timeout="auto" unmountOnExit>
                   <Box sx={{border: '1px solid #E0E0E0', backgroundColor: '#FAFAFA'}}>
                     <List component="div" disablePadding>
                       {item.polygon_type === "from_layer" && (
                         <>
                           <ListItem sx={{ pl: 4 }}>
                             <ListItemText primary={t('metric_type')} primaryTypographyProps={overline} />
                           </ListItem>
                           <ListItemIcon sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', pl: '35px' , gap: '6px'}}>
                             <SquareFoot />
                             <ListItemText primary={item.area + ' (m2)'} primaryTypographyProps={body2} />
                           </ListItemIcon>
                           {newColumns.map((col) => (
                             <Fragment key={col.id}>
                               <ListItem sx={{ pl: 4 }}>
                                 <ListItemText primary={col.name} primaryTypographyProps={overline} />
                               </ListItem>
                               <ListItem sx={{ pl: 4}}>
                                 <ListItemIcon sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', pl: '35px' }}>
                                   <CircleIcon sx={{ mr: '12px' , width: '5px', height: '5px' , marginLeft: '9px'}} />
                                   <ListItemText primary={item[col.field]} primaryTypographyProps={body2}/>
                                 </ListItemIcon>
                               </ListItem>
                             </Fragment>
                           ))}
                         </>
                       )}
                       {item.polygon_type === 'isochrone' && (
                           <>
                             <ListItem sx={{ pl: 4 }}>
                              <ListItemText primary={t('metric_type')} primaryTypographyProps={overline} />
                             </ListItem>
                             {item.isochroneType === 'distance' && (
                               <ListItemIcon sx={{ pl: '35px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                 <SquareFoot />
                                 <ListItemText primary={item.distanceKm/1000 + ' (Km)'} primaryTypographyProps={body2} />
                               </ListItemIcon>
                             )}
                             {item.isochroneType === 'time' && (
                               <ListItemIcon sx={{pl: '35px', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px'}}>
                                 <AccessTime />
                                 <ListItemText primary={item.timeMin + ' (min)'} primaryTypographyProps={body2} />
                               </ListItemIcon>
                               ) }
                           </>
                       )}
                     </List>
                   </Box>
                 </Collapse>
                 <Divider/>
               </Fragment>
             ))}
           </MuiList>
      </Box>
      <Box sx={paginationStyle}>
        <TablePagination
          component="div"
          count={searchFilteredData.length}
          page={page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          color="secondary"
          labelDisplayedRows={({from, to, count}) => `Showing ${from} to ${to} of ${count}`}
          rowsPerPageOptions={[]}
          showLastButton
          sx={{
            '.MuiTablePagination-toolbar': {
              flexWrap: 'wrap',
              minHeight: '48px',
              padding: '0 10px 0 10px'
            },
            '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
              marginRight: '2px',
              marginLeft: '0px',
              fontFamily: 'Montserrat',
              fontSize: '12px',
              color: '#475569',

            },
          }}
        />
      </Box>
      </Box>
    </MuiDivRoot>
  );
}

export default PolygonWidgetUI;
