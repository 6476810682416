import * as React from 'react';
import { Typography, Grid,  IconButton } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NavLink, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useTranslation } from 'components/providers/TranslationProvider';
import { useAuth } from 'components/providers/AuthProvider';
import { TextField } from 'components/common/ReactHooksFormFields';
import Toast from 'components/common/Toast';
import {
  containerButtonsStyle,
  MuiBox, MuiButtonSubmit,
  MuiDivPaper, MuiForgotContent,
  MuiTypographyForgot
} from './styles/login';

const schema = yup.object().shape({
  email: yup.string().email('email_required').required('required'),
});

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [successfully, setSuccessfully] = React.useState(false);
  const location = useLocation();

  const getDefaultValues = () => {
    return location.state
      ? {
          email: location.state,
        }
      : {};
  };

  const { forgotPassword, isPendingAuth } = useAuth();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const handleCloseToast = () => {
    setOpenToast(false);
  };
  const handleSuccess = () => {
    setSuccessfully(true);
  };

  const handleError = (e) => {
    setError(t(e));
    setOpenToast(true);
  };

  const onSubmit = (data) =>
    forgotPassword({ email: data.email, redirectUrl: '/' }, handleError, handleSuccess);

  return (
    <MuiDivPaper sx={{ width:'328px'}}>
      <Toast message={error} handleClose={handleCloseToast} severity='error' open={openToast} />
      <MuiBox>
        <IconButton
        component={NavLink}
        to="/login"
        aria-label="back"
      >
        <ArrowBackIcon style={{color: 'black'}} />
      </IconButton>
      </MuiBox>
      <MuiTypographyForgot>
        {t('forgot_password')}
      </MuiTypographyForgot>
     {!successfully &&
     <MuiForgotContent component='p' variant='body1'>
       {t('password_forgot_info')}
      </MuiForgotContent>
     }
      <FormProvider {...methods}>
        <form style={{width: '328px'}} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {successfully && <Typography>{t('pasword_forgot_success')}</Typography>}
              <TextField
                autoFocus
                variant='outlined'
                fullWidth
                label={t('email')}
                name='email'
                autoComplete='email'
                error={t(errors.email?.message)}
                disabled={successfully}
                data-cy="forgotPasswordEmail"
              />
            </Grid>
          </Grid>
          <Grid container style={{...containerButtonsStyle, marginTop: 0}}>
            <Grid item xs={12} style={{containerButtonsStyle, marginTop: 0}}>
              <MuiButtonSubmit
                loading={isPendingAuth}
                isValid={isValid}
                type='submit'
                fullWidth
                disabled={!isValid || isPendingAuth || successfully}
                data-cy="forgotPasswordSubmit"
                sx={{ width: '328px', marginTop: '28px', height: '37px !important' }}
              >
                {isPendingAuth ? t('sending...') : t('send_email')}
              </MuiButtonSubmit>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </MuiDivPaper>
  );
};

export default ForgotPassword;
