import { widgetType } from 'utils/constants';

export const giveMeId = (pathname, user) => {
  if (pathname.split('/')[2] === 'workspace') return user.id;
  return pathname.split('/')[2];
};

export const giveMeMapId = (pathname) => {
  return pathname.split('/')[4];
}

export const getWidgetsWithFiltersByLayerId = ( layerId, datasources, layers, widgets ) => {
  const filters = [];
  if ( datasources[ layerId ]?.filters) {
    const currentFilters = datasources[ layerId ].filters;
    if ( currentFilters && Object.keys(currentFilters).length > 0 ) {
      const layer = layers.find( (l) => l.id === layerId )
      if ( layer ) {
        Object.keys(currentFilters).forEach(keyProp => {
          if (currentFilters[keyProp].between || currentFilters[keyProp].in) {
            const type = currentFilters[keyProp].between ? 'between' : 'in';
            const idWidgetFiltering = currentFilters[keyProp][type].owner;
            const filteredWidgetsByLayer = widgets.filter( (w) => (w.layer === layerId && w.id === idWidgetFiltering ) );
            if (filteredWidgetsByLayer?.length > 0 ) {
              filteredWidgetsByLayer.forEach(w => {
                let strFilter= '';
                if ( w.type === widgetType.CATEGORY ) {
                  strFilter = getCategoryStringFiltersSeparatedByCommas( currentFilters[keyProp][type].values );
                } else {
                  strFilter = getHistogramStringFormatLimitFilter( currentFilters[keyProp][type].values )
                }
                filters.push( {
                  type: w.type,
                  layerColor: layer.color,
                  widgetName: w.name,
                  widgetId: w.id,
                  filter: strFilter,
                  column: keyProp,
                  layerId: layer.id,
                  layerIcon: layer.customIcon,
                  filtersLogicalOperator: datasources[ layerId ]?.filtersLogicalOperator,
                  formulaField: w.params.calculator ? w.params.field : ''
                })
              });
            }
          }
        });
      }
    }
  }
  return filters;
}

export const getPolygonWidgetsWithFilters = ( widgets, polygonFilters ) => {
  let filters = [];
  if ( polygonFilters && Object.keys( polygonFilters).length > 0 ) {
    const polygonData = Object.values( polygonFilters )[0];
    const selectedWidget = widgets.find( (w) => w.id === polygonData.widgetId );
    const polygonIndex = filters.findIndex( filter => filter.type === 'polygon' );
    const isPolygon = selectedWidget.type === 'polygon';
    const recommendationsDatasetName = !isPolygon && `incluia-project.incluia_datasets.user_${ getDynamicRecommendationsName( selectedWidget.params.isGlobal ) }_scenario_${selectedWidget.id}_recommended_offers`;
    if ( polygonIndex > 0 ) {
      filters[polygonIndex] = {
        type: selectedWidget.type,
        layerColor: isPolygon ? selectedWidget.params.layerColor : selectedWidget.params.layersConfiguration.recommendedPointLayerColor,
        widgetName: selectedWidget.name,
        widgetId: selectedWidget.id,
        filter: '',
        column: '',
        layerId: '',
        layerIcon: '',
        spatialFilter: {
          polygonsId: [ ...polygonData.polygons ],
          spatialTable: isPolygon ? selectedWidget.params.polygonDataset : recommendationsDatasetName,
        }
      }
    } else {
      filters.push({
        type: selectedWidget.type,
        layerColor: isPolygon ? selectedWidget.params.layerColor : selectedWidget.params.layersConfiguration.recommendedPointLayerColor,
        widgetName: selectedWidget.name,
        widgetId: selectedWidget.id,
        filter: '',
        column: '',
        layerId: '',
        layerIcon: '',
        spatialFilter: {
          polygonsId: [ ...polygonData.polygons ],
          spatialTable: isPolygon ? selectedWidget.params.polygonDataset : recommendationsDatasetName,
        }
      })
    }
  }
  return filters;
}

const getCategoryStringFiltersSeparatedByCommas = ( filterValues ) => {
  return filterValues.join(",");
}

const getHistogramStringFormatLimitFilter = ( filterValues ) => {
  return `${ filterValues[0][0] || filterValues[0][1] } - ${filterValues[ filterValues.length - 1 ][1] || filterValues[ filterValues.length - 1 ][0] }`
}

const getDynamicRecommendationsName = (isWidgetGlobal) => {
  let dynamicRecommendationsName = '';
  const userId = JSON.parse(localStorage.getItem('user')).id;
  const org_id = location.pathname.split('/')[2];
  const mapId = (location.pathname.split('/'))[6];

  if (isWidgetGlobal || isWidgetGlobal === null || isWidgetGlobal === undefined) {
    dynamicRecommendationsName = mapId + '_' + org_id;
  } else {
    dynamicRecommendationsName = mapId + '_' + userId;
  }
  return dynamicRecommendationsName;
}

export const transformParametersCatalog = ( parametersCatalog ) => {
  const localParametersCatalog = {};
  parametersCatalog.forEach(p => {
    localParametersCatalog[p.propName] = p.value
  });
  return localParametersCatalog;
}

export const parseFormula = (formula, columns) => {
  if (!Array.isArray(columns) || columns.length === 0) {
    return [];
  }
  const regex = new RegExp(`(${columns.join('|')})`, 'g');
  const result = [];
  let lastIndex = 0;
  let match;
  if (Array.isArray(formula)) {
    formula = formula.join(' ');
  }

  while ((match = regex.exec(formula)) !== null) {
    const index = match.index;
    const column = match[0];

    if (lastIndex < index) {
      result.push(formula.slice(lastIndex, index).trim());
    }
    result.push(column);
    lastIndex = index + column.length;
  }
  if (lastIndex < formula.length) {
    result.push(formula.slice(lastIndex).trim());
  }

  return result.filter(item => item.length > 0);
};
export const buildQuery = (filters, widgets, operator = 'and') => {
  let query = 'where '
  if (filters) {
    if (Object.keys(filters).length > 0) {
      Object.keys(filters).forEach(keyProp => {
        if (filters[keyProp].between && filters[keyProp].between.values.length > 0) {
          const size = filters[keyProp].between.values.length - 1;
          const values = filters[keyProp].between.values;
          const column = widgets.find((w) => w.id === filters[keyProp].between.owner).params.field;
          query += query == 'where ' ? '' : ` ${operator} `;
          if (values[0][0] == null) {
            query += `${column} <= ${values[size][1] ? values[size][1] : values[size][0]} `
          } else {
            query += `${column} >= ${values[0][0] != null ? values[0][0] : values[0][1]} `;
            query += `and ${column} <= ${values[size][1] ? values[size][1] : values[size][0]} `
          }
        }
        if (filters[keyProp].in && filters[keyProp].in.values.length > 0) {
          query += query == 'where ' ? '' : ` ${operator} `;
          const column = widgets.find((w) => w.id === filters[keyProp].in.owner).params.field;
          query += `${column} in (`;
          filters[keyProp].in.values.forEach((x, index) => {
            const clave = typeof x == 'string' ? `'${x}'` : x;
            query += index == filters[keyProp].in.values.length - 1 ? `${clave}) ` : `${clave}, `;
          })
        }
      })
    }
  }
  return query;
}

export const makeid = () => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const length = 10;
  let result = '';
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}
