import { Box, Grid, IconButton, styled, Typography } from '@mui/material';
import { GLOBAL } from '../../../../../../../utils/constants';
import VisibilityOffOutlinedIcon
  from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  marginTop: 0,
  padding: 10,
  color:GLOBAL.COLOR_THEME.PRIMARY,
  backgroundColor: theme.palette.common.white,
  borderRadius: 12,
  '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.grey[100] },
  width: '40px',
  height: '40px',
}));

export const MuiGridRoot = styled(Grid)(({ theme }) => ({
  ...theme.typography.caption,
  padding: theme.spacing(0),
  backgroundColor: theme.palette.common.white,
  maxWidth: 308,
  width: 255,
  borderRadius: 12,
  marginBottom: 6,
  boxShadow: '0px 4px 8px 0px #00000026',
}));

export const MuiGridContainerLegend = styled(Grid)(({ theme }) => ({
  margin: '16px 0px 12px 0px',
  overflow: 'auto',
  minHeight: 50,
  maxHeight: 436,
}));

export const MuiGridItemContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1.2),
  paddingLeft: '12px',
  justifyContent: 'space-between',
  marginBottom: '8px',
}));

export const MuiGridItemLegend = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyItems: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(0),
  padding: '8px 8px 0px 18px',
}));

export const MuiGridColorAndText = styled(Grid)(({ theme }) => ({
  justifyContent: 'flex-start',
  display: 'flex',
  alignItems: 'center',
  maxWidth: '170px'
}));

export const MuiGridControlButtons = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
}));

export const MuiTitle = styled(Typography)(({ theme }) => ({
  margin: '12px 0 0 12px',
  color: 'black',
  fontSize: 14,
  fontWeight: 600,
  fontFamily: 'Montserrat'
}));

export const MuiLayerName = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  marginLeft: 8,
  color: '#424242',
  fontFamily: 'Montserrat'
}));

export const MuiLegendName = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  fontSize: 10,
  fontFamily: 'Montserrat'
}));

export const MuiBoxColor= styled(Box)(({ theme }) => ({
  height: 24,
  width: 24,
  borderRadius: 14,
  marginRight: theme.spacing(1),
}));

export const MuiBoxIconContainerSecondLevel= styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 24,
  width: 24,
  borderRadius: 14,
  marginRight: theme.spacing(1),
}));

export const MuiBoxContainer= styled(Box)(({ theme }) => ({
  height: 24,
  width: 24,
  borderRadius: 14,
  marginRight: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 2,
}));

export const MuiVisibilityOffIcon = styled(VisibilityOffOutlinedIcon)(({ theme }) => ({
  width: '24px',
  height: '24px',
  padding: '4px'
}));

export const MuiVisibilityIcon = styled(VisibilityIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: '24px',
  height: '24px',
  padding: '4px',
}));
